const translate = {
	messages: {
		ru: {
            'Видеозвонки теперь внутри':'Видеозвонки теперь внутри',
            'Продавайте быстрее, делая бесплатные показы авто прямо из приложения':'Продавайте быстрее, делая бесплатные показы авто прямо из приложения',
            'Проверяйте каждую деталь авто':'Проверяйте каждую деталь авто',
            'из своего смартфона':'из своего смартфона',
            'Видеозвонки помогают проверить детали, которых нет на фото':'Видеозвонки помогают проверить детали, которых нет на фото',
            'и задать все вопросы, глядя на продавца':'и задать все вопросы, глядя на продавца',
            'За день вы можете рассмотреть автомобили сразу из нескольких городов и принять решение о личном осмотре лучшего из вариантов':'За день вы можете рассмотреть автомобили сразу из нескольких городов и принять решение о личном осмотре лучшего из вариантов',
			'Устройте первичный показ авто онлайн':'Устройте первичный показ авто онлайн',
			'Освободите свое время для встреч с уже действительно заинтересованными людьми':'Освободите свое время для встреч с уже действительно заинтересованными людьми',
            'За день вы сможете делать показы покупателям сразу из нескольких городов, чтобы ускорить их решение о личном осмотре авто':'За день вы сможете делать показы покупателям сразу из нескольких городов, чтобы ускорить их решение о личном осмотре авто',
            'Позвольте покупателю убедится, что авто в порядке, показав его детально по видеосвязи':'Позвольте покупателю убедится, что авто в порядке, показав его детально по видеосвязи',
            'Держите учет всех звонков':'Держите учет всех звонков',
            'История всех звонков и контакты покупателей':'История всех звонков и контакты покупателей',
            'с которыми вы общались, сохраняется в':'с которыми вы общались, сохраняется в',
            'приложении':'приложении',
			'Мессенджер внутри':'Мессенджер внутри',
			'Контакты продавцов и покупателей, с которыми вы общались, сохранятся в приложении':'Контакты продавцов и покупателей, с которыми вы общались, сохранятся в приложении',
            'Для вопросов о продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении':'Для вопросов о продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении',
			'Для вопросов о покупке и продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении':'Для вопросов о покупке и продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении',
            'Как это работает':'Как это работает',
            'Жмите кнопку':'Жмите кнопку',
            'Бесплатно через':'Бесплатно через',
            'странице предложения':'странице предложения',
            'Для просмотра или показа авто включите камеру':'Для просмотра или показа авто включите камеру',
            'Мы запустили видеозвонки, чтобы продавцы и покупатели доверяли':'Мы запустили видеозвонки, чтобы продавцы и покупатели доверяли',
            'друг другу еще больше, а продажа авто стала максимально быстрой':'друг другу еще больше, а продажа авто стала максимально быстрой',
            'Загрузите, чтобы звонить из приложения':'Загрузите, чтобы звонить из приложения',
            'Или получите ссылку в':'Или получите ссылку в',
            'Ваш номер телефона':'Ваш номер телефона',
            'Отправить':'Отправить',
            'Введите правильный номер':'Введите правильный номер'
		},
		uk: {
            'Видеозвонки теперь внутри':'Відеодзвінки тепер всередині',
            'Продавайте быстрее, делая бесплатные показы авто прямо из приложения':'Продавайте швидше, здійснюючи безкоштовні покази авто прямо з додатку',
            'Проверяйте каждую деталь авто':'Перевіряйте кожну деталь авто',
            'из своего смартфона':'зі свого смартфона',
            'Видеозвонки помогают проверить детали, которых нет на фото':'Відеодзвінки допомагають перевірити деталі, яких немає на фото',
            'и задать все вопросы, глядя на продавца':'і задати всі питання, дивлячись на продавця',
            'За день вы можете рассмотреть автомобили сразу из нескольких городов и принять решение о личном осмотре лучшего из вариантов':'За день ви можете розглянути автомобілі відразу з декількох міст і прийняти рішення про особистий огляд одного з найкращих варіантів',
            'Устройте первичный показ авто онлайн':'Влаштуйте первинний показ авто онлайн',
            'Освободите свое время для встреч с уже действительно заинтересованными людьми':'Звільніть свій час для зустрічей з уже дійсно зацікавленими людьми',
            'За день вы сможете делать показы покупателям сразу из нескольких городов, чтобы ускорить их решение о личном осмотре авто':'За день ви зможете зробити покази покупцям зразу з декількох міст, щоб прискорити їх рішення про особистий огляд авто',
            'Позвольте покупателю убедится, что авто в порядке, показав его детально по видеосвязи':'Дозвольте покупцеві переконатися, що авто в порядку, показавши його детально по відеозв\'язку',
            'Держите учет всех звонков':'Тримайте облік всіх дзвінків',
            'История всех звонков и контакты покупателей':'Історія всіх дзвінків і контакти покупців',
            'с которыми вы общались, сохраняется в':'з якими ви спілкувалися, зберігається в',
            'приложении':'додатку',
            'Мессенджер внутри':'Мессенджер всередині',
            'Контакты продавцов и покупателей, с которыми вы общались, сохранятся в приложении':'Контакти продавців і покупців, з якими ви спілкувалися, збережуться в додатку',
            'Для вопросов о покупке и продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении':'Для питань про купівлю та продаж авто тепер не потрібно перемикатися на інші додатки. Все для спілкування - в додатку',
            'Для вопросов о продаже авто теперь не нужно переключаться на другие приложения. Все для общения – в приложении':'Для питань про продаж авто тепер не потрібно перемикатися на інші додатки. Все для спілкування - в додатку',
            'Как это работает':'Як це працює',
            'Жмите кнопку':'Тисніть кнопку',
            'Бесплатно через':'Безкоштовно через',
            'странице предложения':'сторінці пропозиції',
            'Для просмотра или показа авто включите камеру':'Для перегляду або показу авто ввімкніть камеру',
            'Мы запустили видеозвонки, чтобы продавцы и покупатели доверяли':'Ми запустили відеодзвінки, щоб допомогти продавцям і покупцям довіряти',
            'друг другу еще больше, а продажа авто стала максимально быстрой':' один одному ще більше, а вибір і продаж авто стали максимально швидкими',
            'Загрузите, чтобы звонить из приложения':'Завантажте, щоб дзвонити з додатка',
            'Или получите ссылку в':'Або отримайте посилання в',
            'Ваш номер телефона':'Ваш номер телефону',
            'Отправить':'Відправити',
            'Введите правильный номер':'Введіть правильний номер'
		}
	}
};
export default translate;
