import {tel as telPattern} from '../../../../constants/patterns';
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Store',
	props: ['index', 'segment', 'androidLink', 'iosLink', 'smartLink', 'huaweiLink'],
	i18n: require('../i18n').default,
	data() {
		return {
			phoneNumber: '',
			errorMsg: '',
			phoneHead: '+380',
			os: '',
			huaweiRegex: /huawei|(nexus\s6p|vog-l29|ane-lx1|INE-LX1|eml-l29|ele-l29)|android.+(bah2?-a?[lw]\d{2})|(MediaPad|MediaPad 7 Youth|IDEOS S7|S7-201c|S7-202u|S7-101|S7-103|S7-104|S7-105|S7-106|S7-201|S7-Slim|M2-A01L|BAH-L09|BAH-W09|AGS-L09|CMR-AL19)/i,
		};
	},
	computed: {
		getLangBatton() {
			return this.langId === 4 ? 'ua' : '';
		}
	},
	methods: {
		...mapActions({
			sendPhone: 'AppVideoCalls/sendPhone'
		}),
		isPhoneValid() {
			return telPattern.test(this.phoneNumber);
		},
		sendSms(event) {
			this.errorMsg = '';
			let phoneNumber = this.phoneNumber;
			phoneNumber = phoneNumber.toString();
			if (!phoneNumber.includes('+380')) {
				this.phoneNumber = this.phoneHead + phoneNumber;
			}

			this.gaEvent('audio_video_landing', 'click_on_send_sms', `audio_video_landing_${this.segment}`);

			if (!this.isPhoneValid()) {
				console.error(this.$t('Введите правильный номер'));
				this.errorMsg = 'open';
				return false;
			} else {
				this.sendPhone({
					phone: this.phoneNumber,
					segment: this.segment
				});
				this.phoneNumber = '';
			}
		},
		getMobileOs() {
			if (navigator && navigator.userAgent.match(this.huaweiRegex)) {
				this.os = 'huawei';
			} else if (navigator && navigator.userAgent.match(/Android/i)) {
				this.os = 'android';
			} else if (navigator && navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i)) {
				this.os = 'ios';
			}
		},
	},
	mounted() {
		this.getMobileOs();
	}
};
