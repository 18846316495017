const {get} = require('../../../helpers/cookie');
export default {
    name: 'C2C',
    props: [],
    i18n: require('../i18n').default,
    data(){
        return {
            iosLink:"https://ad.apps.fm/OXlLE9cQBHsafGiRQItclfE7og6fuV2oOMeOQdRqrE2QRCSx0xu66NOS-Oqib1VdlYKRzMT2U-h2iC278Iblyw",
            androidLink: "https://ad.apps.fm/r4BiwUh3DrDMLfmdGsVm8F5KLoEjTszcQMJsV6-2VnHFDLXitVHB6BlL95nuoNYf5E1p4HIJsSgSZiMa1FOvpaDuL1wu-zO5AOyrumm67kU",
			huaweiLink: 'https://appgallery.cloud.huawei.com/ag/n/app/C101464449?channelId=web+promo+page+auto.ria&referrer=web+promo+page+auto.ria&id=f38e9c2560d14c838d3ace0bc8181cde&s=B74C645CC50E67106596D2317A69B95E41D5314063944F021532BE6F6EBE0BF5&detailType=0&v=',
            showAppButtons: true,
        }
    },
    components: {
        'vue-slider': require('../Slider/index.vue').default,
        'vue-store': require('../Store/index.vue').default,
    },
    mounted() {
        if (!Number(get('siteAppBanner'))) {
            this.showAppButtons = true
        }
    },
    computed: {
        srcImg1() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/1/${this.langCode === 'ru' ? '12755/12755' : '12839/12839'}.png`;
        },
        srcImg2() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/1/${this.langCode === 'ru' ? '12840/12840' : '12841/12841'}.png`;
        },
        srcImg3() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/1/${this.langCode === 'ru' ? '12846/12846' : '12847/12847'}.png`;
        },
        srcImg4() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/1/${this.langCode === 'ru' ? '12842/12842' : '12843/12843'}.png`;
        },
        srcImg5() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/1/${this.langCode === 'ru' ? '12844/12844' : '12845/12845'}.png`;
        },
    },
};
