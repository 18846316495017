import {mapGetters, mapActions} from 'vuex';

export default {
    data(){
        return {
            title: this.langId === 4 ? 'App AUTO.RIA - Завантажуйте і телефонуйте безкоштовно прямо з додатку' : 'App AUTO.RIA - Загружайте и звоните бесплатно прямо из приложения',
            description: this.langId === 4 ? 'Завантажуйте App AUTO.RIA і користуйтеся безкоштовними відеодзвінками прямо в додатку. Перевіряйте кожну деталь авто зі свого смартфона. Відео виклик в додатку AUTO.RIA допомагає перевірити кожну деталь авто, влаштувати первинний показ авто онлайн і зекономити час.' : 'Загружайте App AUTO.RIA и пользуйтесь бесплатными видеозвонками прямо в приложении. Проверяйте каждую деталь авто из своего смартфона. Видеозвонки в приложении AUTO.RIA помогают проверить каждую деталь авто, устраивать первичный показ авто онлайн и экономить время.'
        };
    },
    i18n: require('./i18n').default,
    init({store,context}){
        let cookies = {};
        if (context) {
            cookies = context.request.headers.cookie;
            return store.dispatch('Kind16Check/loadSegment', cookies)
                .catch(err => console.error('ERROR:::Kind16Check/loadSegment', err.message));
        }
    },
	name: 'AudioVideoAPP',
    mounted(){

    },
    methods: {
        ...mapActions({
            loadSegment: 'Kind16Check/loadSegment'
        }),
    },
	computed: {
        ...mapGetters({
            langId: 'lang/id',
            langPrefix: 'lang/prefix',
            userData: 'Common/userData',
            segment: 'Kind16Check/segment'
        })
	},
     components: {
        'vue-page-c2c': require('./C2C/index.vue').default,
        'vue-page-smp-b2c': require('./SmbB2C/index.vue').default
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [
                {property: 'og:title', content: this.title},
                {name: 'description', content: this.description},
                {property: 'og:description', content: this.description},
                {name: "keywords", content: "" },
                {property: 'fb:app_id', content: '214702429075634'},
            ],
            link: [
                { rel: "alternate", hreflang: "ru-UA", href: "https://auto.ria.com/" },
                { rel: "alternate", hreflang: "uk-UA", href: "https://auto.ria.com/uk/" },
                { rel: "alternate", hreflang: "ru", href: "https://auto.ria.com/" },
                { rel: "alternate", hreflang: "uk", href: "https://auto.ria.com/uk/" },
                { rel: "alternate", hreflang: "x-default", href: "https://auto.ria.com/" }
            ]
        };
    }
};




